import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/public.scss'
import 'element-ui/lib/theme-chalk/index.css'
import './utils/elementRequire'
import Aegis from 'aegis-web-sdk'

new Aegis({
    id: 'kwmxdsK16ogdG10Zga', // 上报 id
    uin: 'go2brick.com', // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: false, // 静态资源测速
    spa: true, // spa 应用页面跳转的时候开启 pv 计算
    hostUrl: 'https://rumt-zh.com',
    delay: 3000
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
