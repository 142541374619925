import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'https://www.pixysoft.cn/sso' : '/sso', //'http://localhost:8001/sso',//'https://www.pixysoft.cn/sso', // 你的API基础URL
    timeout: 10000 // 请求超时时间
})

// 请求拦截器
instance.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么，例如设置token
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 响应拦截器
instance.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        return response
    },
    function (error) {
        // 对响应错误做点什么
        return Promise.reject(error)
    }
)

const instancePhp = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://www.pixysoft.cn/php' : '/php', // 你的API基础URL
    timeout: 10000 // 请求超时时间
})

// 请求拦截器
instancePhp.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么，例如设置token
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 响应拦截器
instancePhp.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        return response
    },
    function (error) {
        // 对响应错误做点什么
        return Promise.reject(error)
    }
)

export { instance, instancePhp }
