import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const Layout = () => import('@/views/layout')
const Home = () => import('@/views/Home')
const Matching = () => import('@/views/matching')
const Help = () => import('@/views/helpcenter')
const Moc = () => import('@/views/moc')

const routes = [
    {
        path: '/',
        name: 'layout',
        component: Layout,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: Home
            },
            {
                path: '/matching',
                name: 'matching',
                component: Matching
            },
            {
                path: '/help',
                name: 'help',
                component: Help
            },
            {
                path: '/moc',
                name: 'moc',
                component: Moc
            }
        ]
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (process.env.NODE_ENV === 'production') {
        if (window.location.protocol !== 'https:') {
            window.location.protocol = 'https:'
        } else if (!window.location.hostname.startsWith('www.')) {
            window.location = `https://www.${window.location.hostname}${window.location.pathname}${window.location.search}${window.location.hash}`
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
