import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    sign: null,
    parts: null,
    servertime_offset: null
}

const getters = Object.keys(state).reduce((result, key) => {
    result[key] = (state) => state[key]
    return result
}, {})

const mutations = Object.keys(state).reduce((result, key) => {
    result[key] = (state, value) => {
        state[key] = value
        // console.log(`[state] set ${key}`, value)
    }
    return result
}, {})

export default new Vuex.Store({
    state: state,
    getters: getters,
    mutations: mutations,
    actions: {},
    modules: {}
})
